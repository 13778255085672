import { render, staticRenderFns } from "./Active.vue?vue&type=template&id=493c70af&scoped=true&"
import script from "./Active.vue?vue&type=script&lang=js&"
export * from "./Active.vue?vue&type=script&lang=js&"
import style0 from "./Active.vue?vue&type=style&index=0&id=493c70af&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493c70af",
  null
  
)

export default component.exports