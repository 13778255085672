<template>
  <div class="content">
    <PageHeader title="Números Ativos">
      <template v-slot:actions>
        <li>
          <router-link
            :to="'/admin/numbers/create'"
            class="btn btn-success btn-floating"
          >
            <i
              class="
                fas fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </li>
      </template>
    </PageHeader>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!numbers.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-phone-alt font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhum remetente cadastrado</h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead class="bg-light" v-if="numbers.length">
                    <tr>
                      <th scope="col" class="text-left">{{$t('generic-str.lbl-sender')}}</th>
                      <th scope="col">{{$t('generic-str.capabilities')}}</th>
                      <th scope="col">{{$tc('generic-str.menu.providers', 2)}}</th>
                      <th scope="col" class="text-right">{{$t('generic-str.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(number, i) in numbers" :key="number.id">
                      <td data-label="Remetente" class="text-left">
                        {{ number.msisdn }}
                      </td>
                      <td scope="row" data-label="Tipo">
                        <span
                          v-for="capability in number.capabilities"
                          :key="capability"
                          class="badge badge-pill badge-success"
                          >{{ capability }}</span
                        >
                      </td>
                      <td data-label="Rota">
                        <span
                          v-for="provider in number.providers"
                          :key="provider.id"
                          class="badge badge-primary"
                          >{{ provider.name }}</span
                        >
                      </td>
                      <td data-label class="text-right">
                        <div>
                          <div class="dropdown" style="margin-right: 15px">
                            <a
                              role="button"
                              class="dropdown-action"
                              :to="'#menu-dropdown-' + i"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="icon dripicons-gear text-success"></i>
                            </a>
                            <div
                              class="dropdown-menu dropdown-menu-right top-35"
                              :id="'menu-dropdown-' + i"
                              x-placement="bottom-end"
                              style
                            >
                              <router-link
                                :to="`/admin/number/${number.id}`"
                                class="dropdown-item"
                              >
                                {{$t('generic-str.edit')}}
                              </router-link>
                              <a
                                v-if="$store.state.auth.user.role == 'owner'"
                                class="dropdown-item"
                                @click="remove(number.id)"
                                >{{$t('generic-str.remove')}}</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import NumberService from '@/services/number.service';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'Numbers',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      numbers: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      NumberService.getActiveNumbers(this.form)
        .then(
          (response) => {
            this.numbers = response.data;
            this.pages = response.last_page;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
